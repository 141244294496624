<div class="sidebar">
  <div class="logo"><img src="../../../assets/ride_loud_icons/ride loud_RIDE LOUD LOGO.png" alt="logo"></div>
  <div class="header-links">

    <a routerLink="/analytics" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/ride_loud_icons/dashboard_black.svg" alt="">
        <img class="white-icon" src="../../../assets/ride_loud_icons/dashboard_white.svg" alt="" height="24" width="24">
      </span>Reporting</a>

    <a routerLink="/sports" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/ride_loud_icons/Sport.svg" alt="">
        <img class="white-icon" src="../../../assets/ride_loud_icons/Sport White.svg" alt="" height="24" width="24">
      </span>Sports</a>


    <a routerLink="/userEvents" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/ride_loud_icons/calendar2-event.svg" alt="">
        <img class="white-icon" src="../../../assets/ride_loud_icons/calendar2-event_white.svg" alt="">
      </span>Events</a>


    <a routerLink="/reported-posts" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/ride_loud_icons/Report.svg" alt="">
        <img class="white-icon" src="../../../assets/ride_loud_icons/Report White.svg" alt="">
      </span>Reported Posts</a>

    <a routerLink="/reported-events" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/ride_loud_icons/Report.svg" alt="">
        <img class="white-icon" src="../../../assets/ride_loud_icons/Report White.svg" alt="">
      </span>Reported Events</a>

    <a routerLink="/reported-users/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/ride_loud_icons/users.svg" alt="">
        <img class="white-icon" src="../../../assets/ride_loud_icons/users_white.svg" alt="">
      </span>Manage Users</a>

    <a routerLink="/edit-profile" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/ride_loud_icons/Chnage Password Black.svg" alt="" height="20"
          width="20">
        <img class="white-icon" src="../../../assets/ride_loud_icons/Change Password.svg" alt="" height="20" width="20">
      </span>Change Password</a>

    <a routerLinkActive="active" (click)="openModal(template)" id="logout">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/ride_loud_icons/Log Out Black.svg" alt="" height="20" width="20">
        <img class="white-icon" src="../../../assets/ride_loud_icons/Log Out.svg" alt="" height="20" width="20">
      </span>Logout</a>

  </div>
</div>

<!-- Logout fonfirm -->
<ng-template #template>
  <div class="modal-header">
    <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../assets/ride_loud_icons/create-successfull-img.svg" alt="">
    <p>Are you sure you want to logout ?</p>
    <div class="modal-btn d-flex justify-content-around">
      <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
      <button class="btn btn-blue" (click)="confirm()">Yes</button>
    </div>
  </div>
</ng-template>